import React from 'react';
import Test from "../components/Test";

// import '../styles/Home.css';

// Test page with test component

export default function Blank() {
    return(
        <>
            <div style={{ textAlign: 'center', }}>
                <Test />
            </div>
        </>
    )
}